import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import Image from "next/image";
import { useEffect, useMemo, useState } from "react";
import { CheckMarkIcon, CloseIcon, PlusIconSmall } from "ui";
import { Layout } from "../shared/Layout";
import { ProductDetailsModal } from "./components/ProductDetailsModal";

export type ProductType = {
  product_id: string;
  name: string;
  description: string;
  price: number | null;
  images: string[];
  offer_id: string;
  selected_quantity: number | null;
};

type EcommerceProps = {
  product_id?: string;
  title?: string;
  description?: string;
  products: ProductType[];
  selectedProducts?: ProductType[];
  simulated?: boolean;
  buttonLabel?: string;
  disabled?: boolean;
  onContinue: (answer: SelectedProduct[]) => void;
  loading?: boolean;
  settings?: {
    auto_proceed_timer: number;
    countdown_timer: number;
  };
  shoppingCartOpen?: boolean;
  setShoppingCartOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setHasProducts?: React.Dispatch<React.SetStateAction<boolean>>;
  container?: HTMLElement | undefined;
  currency?: Currency;
  addToCartLabels: {
    added: string;
    total: string;
    shopping_cart: string;
    add_to_cart: string;
  };
  enableAddToCartButton?: boolean;
};

export interface Currency {
  symbol: string;
  currency_code: string;
  precision: number;
}

export type SelectedProduct = {
  selected_quantity: number;
  product_id: string;
} & ProductType;

export const Ecommerce = ({
  products,
  disabled,
  onContinue,
  description,
  loading,
  settings = { auto_proceed_timer: 0, countdown_timer: 0 },
  buttonLabel = "Continue",
  shoppingCartOpen,
  setShoppingCartOpen,
  setHasProducts,
  selectedProducts: allSelectedProducts = [],
  addToCartLabels,
  enableAddToCartButton = false,
  ...props
}: EcommerceProps) => {
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(
    [],
  );

  const [hoveredProduct, setHoveredProduct] = useState<string | null>(null);

  const [showProductDetails, setShowProductDetails] = useState<
    string | boolean
  >(false);

  const product = useMemo(() => {
    return showProductDetails
      ? products.find((product) => product.product_id === showProductDetails)
      : null;
  }, [showProductDetails]);

  const handleAddToCart = (product: SelectedProduct) => {
    setSelectedProducts((selectedProducts) => {
      const result = isAlreadyAddedToCart(product.product_id)
        ? selectedProducts.filter(
            (item) => item.product_id !== product.product_id,
          )
        : [product, ...selectedProducts];
      setHasProducts?.(Boolean(result.length));
      return result;
    });
  };

  const isAlreadyAddedToCart = (product_id: string) => {
    return Boolean(
      selectedProducts?.find((item) => item.product_id === product_id),
    );
  };

  const totalPrice = selectedProducts.reduce((acc, item) => {
    return acc + (item.price || 0) * item.selected_quantity;
  }, 0);

  useEffect(() => {
    if (allSelectedProducts?.length) {
      const formattedProducts: SelectedProduct[] = allSelectedProducts.map(
        (product) => ({
          ...product,
          selected_quantity: product.selected_quantity || 1,
        }),
      );
      setSelectedProducts(formattedProducts);
    }
  }, [allSelectedProducts]);

  const uniqueOfferIds = selectedProducts
    .map((item) => item.offer_id)
    .filter((value, index, self) => self.indexOf(value) === index);

  const onContinueData: any = {
    products_input: selectedProducts
      .filter((product) =>
        Boolean(products.find((p) => p.product_id === product.product_id)),
      )
      .map((product) => ({
        product_id: product.product_id,
        selected_quantity: product.selected_quantity,
      })),
    cart_overview: uniqueOfferIds.reduce(
      (a, offer_id) => ({
        ...a,
        [offer_id]: selectedProducts.reduce(
          (a, v) =>
            v.offer_id === offer_id
              ? {
                  ...a,
                  [v.product_id]: { selected_quantity: v.selected_quantity },
                }
              : a,
          {},
        ),
      }),
      {},
    ),
  };

  return (
    <>
      <Layout
        title={description}
        loading={loading}
        disabled={disabled}
        buttonLabel={buttonLabel}
        auto_proceed_timer={settings.auto_proceed_timer}
        countdown_timer={settings.countdown_timer}
        buttonContainerStyle={{
          display: ["block", "none"],
        }}
        onContinue={() => {
          onContinue(onContinueData);
          setShowProductDetails(false);
        }}
      >
        <Box
          sx={{
            p: [3, 0],
            boxShadow: [
              "inset 0 1px 0 0 var(--mui-palette-layout-border-color)",
              "none",
            ],
          }}
        >
          <Box
            sx={{
              mt: [5, 4],
              mb: [5, 4],
              display: "grid",
              gridGap: "16px",
              rowGap: "24px",
              justifyItems: "center",
              gridTemplateColumns: [
                "repeat(auto-fill, minmax(140px, 1fr))",
                "repeat(auto-fill, minmax(140px, 1fr))",
              ],
            }}
          >
            {products.map((product) => {
              return (
                <Box
                  key={product.product_id}
                  onMouseLeave={() => setHoveredProduct(null)}
                  onMouseEnter={() => setHoveredProduct(product.product_id)}
                  onClick={() => setShowProductDetails(product.product_id)}
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    cursor: "pointer",
                    flexDirection: "column",
                  }}
                >
                  <Stack spacing={1} sx={{ height: "100%" }}>
                    <Image
                      width={200}
                      height={200}
                      alt={product.name}
                      style={{
                        width: "100%",
                        height: "140px",
                        objectFit: "cover",
                        borderRadius: "6px",
                      }}
                      src={product.images?.[0]}
                    />
                    <Stack spacing={0.3} sx={{ mb: "auto !important" }}>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        sx={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          "@supports (-webkit-line-clamp: 2)": {
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "initial",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                          },
                        }}
                      >
                        {product.name}
                      </Typography>

                      <Typography variant="body2">
                        {truncateString(
                          removeHtmlFromString(product.description),
                          18,
                        )}
                      </Typography>

                      {typeof product?.price === "number" && (
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          color={
                            hoveredProduct === product.product_id
                              ? "var(--mui-palette-options-active-color)"
                              : "var(--mui-palette-layout-text-color)"
                          }
                        >
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: props?.currency?.currency_code,
                          }).format(product.price)}
                        </Typography>
                      )}
                    </Stack>

                    {enableAddToCartButton && (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();

                          !loading &&
                            handleAddToCart({
                              ...product,
                              selected_quantity: 1,
                            });
                        }}
                        startIcon={
                          isAlreadyAddedToCart(product.product_id) ? (
                            <CheckMarkIcon color="var(--mui-palette-options-active-color)"/>
                          ) : (
                            <PlusIconSmall color="var(--mui-palette-options-default-color)" />
                          )
                        }
                        variant="outlined"
                        sx={{
                          pl: "8px",
                          pr: "16px",
                          fontWeight: 400,
                          fontSize: "14px",
                          width: "fit-content",
                          color: "var(--mui-palette-options-text-color)",
                          backgroundColor: isAlreadyAddedToCart(
                            product?.product_id,
                          )
                            ? "var(--mui-palette-options-active-background-color)"
                            : "var(--mui-palette-options-background-color)",
                          border: isAlreadyAddedToCart(product?.product_id)
                            ? "solid 1px var(--mui-palette-options-active-border-color)"
                            : "solid 1px var(--mui-palette-options-border-color)",
                          borderRadius:
                            "var(--mui-palette-options-corners-rounding)",
                          ":hover": {
                            backgroundColor:
                              "var(--mui-palette-options-hover-background-color)",
                          },
                          ".MuiButton-startIcon": {
                            mr: 0,
                          },
                        }}
                      >
                        {isAlreadyAddedToCart(product?.product_id)
                          ? addToCartLabels?.added || "Added"
                          : addToCartLabels?.add_to_cart || "Add to cart"}
                      </Button>
                    )}
                  </Stack>
                </Box>
              );
            })}
          </Box>
          <Button
            variant="contained"
            sx={{
              padding: "11.8px 30.2px",
              width: { xs: "100%", sm: "auto" },
              height: { xs: "48px", sm: "auto" },
              minWidth: "120px",
              display: ["none", "block"],
              "&:hover": {
                backgroundImage: "none",
              },
              overflow: "hidden",
            }}
            disabled={disabled || loading}
            onClick={() => {
              onContinue(onContinueData);
              setShowProductDetails(false);
            }}
          >
            {loading ? <CircularProgress size={18} /> : buttonLabel}
          </Button>
        </Box>
      </Layout>
      {product && (
        <ProductDetailsModal
          product={product}
          isOpen={Boolean(showProductDetails)}
          onClose={() => setShowProductDetails(false)}
          selected={isAlreadyAddedToCart(product?.product_id)}
          onSelect={(product) => handleAddToCart(product)}
          container={props?.container}
          currency={props?.currency}
          addToCartLabels={addToCartLabels}
          enableAddToCartButton={enableAddToCartButton}
        />
      )}
      {shoppingCartOpen && (
        <ShoppingCart
          totalPrice={totalPrice}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          setShoppingCartOpen={setShoppingCartOpen}
          currency={props?.currency}
          addToCartLabels={addToCartLabels}
        />
      )}
    </>
  );
};

const ShoppingCart = ({
  totalPrice,
  selectedProducts,
  setSelectedProducts,
  setShoppingCartOpen,
  currency,
  addToCartLabels,
}: {
  totalPrice: number;
  selectedProducts: SelectedProduct[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<SelectedProduct[]>>;
  setShoppingCartOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  currency?: Currency;
  addToCartLabels: {
    shopping_cart: string;
    total: string;
  };
}) => {
  return (
    <Box
      sx={{
        top: "0px",
        right: "0px",
        width: "300px",
        height: "100vh",
        display: "flex",
        position: "fixed",
        zIndex: 9999,
        flexDirection: "column",
        borderLeft: "1px solid var(--mui-palette-layout-border-color)",
        background: "var(--mui-palette-layout-background-color)",
      }}
    >
      <Box
        sx={{
          p: "11.6px",
          borderBottom: "1px solid var(--mui-palette-layout-border-color)",
          display: "flex",
          alignContent: "center",
          justifyContent: "space-between",
          svg: { cursor: "pointer" },
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "14px" }}>
          {addToCartLabels?.shopping_cart || "Shopping Cart"}
        </Typography>
        <CloseIcon
          color="var(--mui-palette-layout-text-color)"
          onClick={() => setShoppingCartOpen?.((p) => !p)}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
        }}
      >
        {selectedProducts.map((product) => {
          return (
            <ProductItem
              key={product.product_id}
              {...product}
              currency={currency}
              handleDecrement={(val) => {
                if (val === 0) {
                  setSelectedProducts(
                    selectedProducts.filter(
                      (item) => item.product_id !== product.product_id,
                    ) as SelectedProduct[],
                  );
                  return;
                }

                setSelectedProducts(
                  selectedProducts.map((item) => {
                    if (item.product_id === product.product_id) {
                      return {
                        ...item,
                        selected_quantity: val,
                      };
                    }
                    return item;
                  }),
                );
              }}
              handleIncrement={(val) => {
                setSelectedProducts(
                  selectedProducts.map((item) => {
                    if (item.product_id === product.product_id) {
                      return {
                        ...item,
                        selected_quantity: val,
                      };
                    }
                    return item;
                  }),
                );
              }}
            />
          );
        })}
      </Box>
      <Box
        sx={{
          p: "12px",
          borderTop: "1px solid var(--mui-palette-layout-border-color)",
          display: "flex",
          alignContent: "center",
          justifyContent: "space-between",
          svg: { cursor: "pointer" },
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "14px" }}>
          {addToCartLabels?.total || "Total"}
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "14px" }}>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency?.currency_code,
          }).format(totalPrice)}
        </Typography>
      </Box>
    </Box>
  );
};

const ProductItem = ({
  name,
  price,
  images,
  selected_quantity,
  handleDecrement,
  handleIncrement,
  currency,
}: {
  handleDecrement: (val: number) => void;
  handleIncrement: (val: number) => void;
  currency?: Currency;
} & ProductType) => {
  return (
    <Box
      sx={{
        p: "16px",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid var(--mui-palette-layout-border-color)",
      }}
    >
      <Box
        sx={{
          mr: "8px",
          width: "40px",
          height: "40px",
          borderRadius: "6px",
          border: "solid 1px var(--mui-palette-layout-border-color)",
        }}
      >
        <Image
          width={40}
          height={40}
          alt={name}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "6px",
          }}
          src={images[0]}
        />
      </Box>
      <Box
        sx={{ display: "flex", marginRight: "auto", flexDirection: "column" }}
      >
        <Typography variant="h6" sx={{ fontSize: "14px" }}>
          {name}
        </Typography>
        {typeof price === "number" && (
          <Typography variant="h6" sx={{ fontSize: "14px" }}>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency?.currency_code,
            }).format(price)}{" "}
          </Typography>
        )}
      </Box>
      <QuantityPicker
        min={0}
        max={10}
        defaultValue={selected_quantity || 1}
        handleDecrement={handleDecrement}
        handleIncrement={handleIncrement}
      />
    </Box>
  );
};

const QuantityPicker = ({
  min,
  max,
  defaultValue,
  handleDecrement,
  handleIncrement,
}: {
  min: number;
  max?: number;
  defaultValue: number;
  handleDecrement: (val: number) => void;
  handleIncrement: (val: number) => void;
}) => {
  const buttonStyles = {
    width: "24px",
    height: "24px",
    display: "flex",
    userSelect: "none",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: "6px",
        alignItems: "center",
        border: "solid 1px rgba(12, 12, 36, 0.06)",
      }}
    >
      <Box
        sx={{
          ...buttonStyles,
          opacity: defaultValue === min ? 0.5 : 1,
          cursor: defaultValue === min ? "not-allowed" : "pointer",
        }}
        onClick={() => defaultValue > min && handleDecrement(defaultValue - 1)}
      >
        -
      </Box>
      <Box
        sx={{
          width: "24px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderLeft: "solid 1px rgba(12, 12, 36, 0.06)",
          borderRight: "solid 1px rgba(12, 12, 36, 0.06)",
        }}
      >
        {defaultValue}
      </Box>
      <Box
        sx={{
          ...buttonStyles,
          opacity: max && defaultValue >= max ? 0.5 : 1,
          cursor: max && defaultValue >= max ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          if (max && defaultValue >= max) {
            return;
          }
          handleIncrement(defaultValue + 1);
        }}
      >
        +
      </Box>
    </Box>
  );
};

export function truncateString(str: string = "", num: number) {
  if (str?.length <= num) {
    return str;
  }
  return str?.slice(0, num) + "...";
}

export function removeHtmlFromString(str: string = ""): string {
  const regex = /(<([^>]+)>)/gi;
  return str.replace(regex, "");
}
