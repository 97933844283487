import { Box, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  FloatingLayer,
  SelectField,
} from "../../../apps/web/src/components/FloatingLayer";
import { SelectFieldValue } from "../../../apps/web/src/components/Select";
import { Layout } from "../shared/Layout";
import { Choice } from "./components";
import MultipleImage from "./components/MultipleImage";

export const Question = ({
  title,
  type,
  view,
  buttonLabel = "Continue",
  choices = [],
  gridView,
  defaultChecked,
  requiredAnswerCount = 0,
  isAnswerRequired,
  requiredOpenEndedAnswers,
  disabled,
  onChange,
  onContinue,
  loading,
  placeholder,
  noResultsMessage,
  choiceInputPlaceholderLabel,
}: Props) => {
  const [checked, setChecked] = useState<ChoiceAnswers>(defaultChecked || null);
  const isRange = Array.isArray(requiredAnswerCount);
  const [min, max] = isRange ? requiredAnswerCount : [requiredAnswerCount];

  const getFormattedChoices = (rawChoices: ChoiceType[]) => {
    return rawChoices.map((choice) => ({
      id: choice.id,
      label: choice.label,
      image: choice.image,
      checked: checked?.some((c) => c.id === choice.id) || false,
      answer: checked?.find((c) => c.id === choice.id)?.answer,
      open_ended: choice.open_ended,
      exclusive: choice.exclusive,
    }));
  };

  const [formattedChoices, setFormattedChoices] = useState<FormattedChoices[]>(
    getFormattedChoices(choices),
  );
  const [searchValue, setSearchValue] = useState("");
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const requireOpenAnswer = !formattedChoices.every((choice) =>
    choice.checked && choice.open_ended ? !!choice.answer : true,
  );

  const hasOpenEndedAnswer = useMemo(() => {
    return requiredOpenEndedAnswers && requireOpenAnswer;
  }, [requiredOpenEndedAnswers, formattedChoices]);

  const handleChange = (
    isChecked: boolean,
    choice: ChoiceType,
    answer?: string,
  ) => {
    const isSingle = type === "single";
    setChecked((prev) => {
      // Create the item object to be added to the checked list
      const item = {
        id: choice.id,
        ...(answer && { answer }),
        label: choice.label,
        is_open_ended: choice.open_ended,
        is_exclusive: choice.exclusive,
      };

      // Handle single selection
      if (isSingle) {
        const newChecked = isChecked ? [item] : null;
        onChange?.(newChecked);
        return newChecked;
      }

      // Handle multiple selection
      const isFirstAnswer = !prev?.length;
      if (isFirstAnswer) {
        const newChecked = [item];
        onChange?.(newChecked);
        return newChecked;
      }

      // Check if the choice is already in the checked list
      const checkIfExist = !!prev?.find((c) => c.id === choice.id);
      if (isChecked) {
        const newChecked = checkIfExist
          ? prev.map((c) => (c.id === choice.id ? { ...c, answer } : c))
          : choice.exclusive || prev.some((c) => c.is_exclusive)
            ? [item]
            : [...prev, item];
        onChange?.(newChecked);

        return newChecked;
      } else {
        // Remove the choice from the checked list
        const newChecked = prev.filter((c) => c.id !== choice.id);
        onChange?.(newChecked);
        return newChecked;
      }
    });
  };

  useEffect(() => {
    setChecked(defaultChecked || null);
  }, [defaultChecked]);

  useEffect(() => {
    setFormattedChoices(getFormattedChoices(choices));
  }, [choices]);

  useEffect(() => {
    setFormattedChoices((pv) => getFormattedChoices(pv));
  }, [checked]);

  const disabledContinueButton = useMemo(() => {
    const checkedLength = (checked?.length || 0) as number;
    const isWithinMinRange = checkedLength >= min;
    const isWithinMaxRange = !isRange || checkedLength <= max;

    if (disabled) {
      return true;
    }
    if (isAnswerRequired) {
      return !isWithinMinRange || !isWithinMaxRange || requireOpenAnswer;
    }
  }, [disabled, checked, isRange, min, max, hasOpenEndedAnswer]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    const allChoices = getFormattedChoices(choices);

    if (value.trim() === "") {
      return setFormattedChoices(allChoices);
    }

    setFormattedChoices(
      allChoices.filter((choice) =>
        choice.label.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  };

  const handleCloseDropdown = () => {
    setIsSelectOpen(false);
    setSearchValue("");
    setFormattedChoices(getFormattedChoices(choices));
  };

  useEffect(() => {
    // Clear search value when checked changes
    setSearchValue("");
  }, [checked]);

  const fieldValue = {
    single: (
      <SelectFieldValue
        placeholder={placeholder}
        searchValue={searchValue}
        searchable={!checked?.[0]?.label}
        autoFocus={isSelectOpen}
        type="single"
        name={checked?.[0]?.label}
        onSearch={handleSearch}
      />
    ),

    multiple: (
      <SelectFieldValue
        placeholder={placeholder}
        searchValue={searchValue}
        searchable
        type="multiple"
        autoFocus={isSelectOpen}
        onSearch={handleSearch}
        items={checked?.map((choice) => ({ name: choice.label })) || []}
        onRemove={() => setChecked((prev) => prev?.slice(0, -1) || null)}
      />
    ),
  };

  const getGridStyle = (gridView: {
    column_on_desktop: number;
    column_on_mobile: number;
  }) => ({
    display: "grid",
    gap: "8px",
    width: "100%",
    gridTemplateColumns: {
      xs: `repeat(${gridView?.column_on_mobile}, 1fr)`,
      md: `repeat(${gridView?.column_on_desktop}, 1fr)`,
    },
  });

  return (
    <Layout
      title={title}
      buttonLabel={buttonLabel}
      onContinue={() => onContinue(checked)}
      disabled={disabledContinueButton}
      loading={loading}
      titleSx={{ img: { width: "100%" } }}
    >
      <Box
        className="question-container"
        sx={{
          overflowX: "hidden",
          pt: { xs: "0", sm: "0", md: "0" },
          pb: { xs: "24px", sm: "24px", md: "24px" },
          px: { xs: "24px", sm: "0px", md: "0px" },
          ...(view !== "dropdown" ? getGridStyle(gridView) : {}),
        }}
      >
        {view === "dropdown" ? (
          <FloatingLayer
            fullWidth
            ignoreReferenceInteraction
            closeOnChildClick={type === "single"}
            onOpen={() => setIsSelectOpen(true)}
            onClose={handleCloseDropdown}
            reference={(open) => (
              <SelectField
                open={open}
                value={fieldValue[type]}
                fieldStyles={{
                  borderColor: "var(--mui-palette-options-default-color)",
                  background:
                    "var(--mui-palette-input_fields-background-color)",
                  color: "var(--mui-palette-input_fields-text-color)",
                  ".select-field-icon": {
                    svg: {
                      fill: "var(--mui-palette-layout-text-color)",
                    },
                  },
                }}
              />
            )}
            popperStyles={() => ({
              //   py: "8px",
              background: "var(--mui-palette-input_fields-background-color)",
              borderRadius: "0px 0px 6px 6px",
              ".popper-container": { maxHeight: "40vh", overflow: "auto" },
            })}
          >
            {formattedChoices.length === 0 ? (
              <NoResults message={noResultsMessage} />
            ) : (
              formattedChoices?.map((choice) => {
                return (
                  <Choice
                    view={view}
                    controlled
                    key={choice.id}
                    {...choice}
                    disabled={loading}
                    onChange={async (checked, answer) => {
                      handleChange(checked, choice, answer);
                      setFormattedChoices(getFormattedChoices(choices));
                    }}
                    variant={
                      type === "single" || choice.exclusive
                        ? "single"
                        : "multiple"
                    }
                    openEnded={choice.open_ended}
                    choiceInputPlaceholderLabel={choiceInputPlaceholderLabel}
                  >
                    {choice.label}
                  </Choice>
                );
              })
            )}
          </FloatingLayer>
        ) : view === "multiple_image" ? (
          formattedChoices?.map((choice) => {
            return (
              <MultipleImage
                key={choice.id}
                choice={choice}
                disabled={loading}
                checked={checked?.some((c) => c.id === choice.id) || false}
                variant={
                  type === "single" || choice.exclusive ? "single" : "multiple"
                }
                onChange={(checked, answer) =>
                  handleChange(checked, choice, answer)
                }
              />
            );
          })
        ) : (
          formattedChoices?.map((choice) => {
            return (
              <Choice
                controlled
                key={choice.id}
                {...choice}
                disabled={loading}
                onChange={(checked, answer) =>
                  handleChange(checked, choice, answer)
                }
                variant={
                  type === "single" || choice.exclusive ? "single" : "multiple"
                }
                openEnded={choice.open_ended}
                choiceInputPlaceholderLabel={choiceInputPlaceholderLabel}
              >
                {choice.label}
              </Choice>
            );
          })
        )}
      </Box>
    </Layout>
  );
};

const NoResults = ({ message = "No results found" }: { message?: string }) => {
  return (
    <Typography
      variant="body1"
      sx={{ textAlign: "center", color: "#747789", py: 1.5 }}
    >
      {message}
    </Typography>
  );
};

export type ChoiceAnswers =
  | {
      id: string;
      answer?: string;
      label?: string;
      is_open_ended?: boolean;
      is_exclusive?: boolean;
    }[]
  | null;

interface SingleProps {
  requiredAnswerCount?: 0 | 1;
}

interface MultipleProps {
  requiredAnswerCount?: number | number[];
}

interface Choice {
  id: string;
  label: string;
}

type Props = {
  simulated?: boolean;
  type: "single" | "multiple";
  view: "dropdown" | "regular" | "multiple_image";
  title: string;
  choices: ChoiceType[];
  gridView: {
    column_on_desktop: number;
    column_on_mobile: number;
  };
  disabled?: boolean;
  onChange?: (value: ChoiceAnswers) => void;
  loading?: boolean;
  buttonLabel?: string;
  defaultChecked?: ChoiceAnswers;
  onContinue: (values: ChoiceAnswers) => void;
  requiredOpenEndedAnswers?: boolean;
  isAnswerRequired?: boolean;
  choiceInputPlaceholderLabel?: string;
  placeholder?: string;
  noResultsMessage?: string;
} & (SingleProps | MultipleProps);

export type ChoiceType = {
  id: string;
  label: string;
  image?: string;
  open_ended: boolean;
  exclusive: boolean;
};

type FormattedChoices = {
  id: string;
  label: string;
  checked: boolean;
  answer?: string;
  open_ended: boolean;
  exclusive: boolean;
};
